<template lang="pug">
  Auth(:redirectWithLoginFailed="true")
    Header(:content="headerContent")
</template>

<style lang="scss" scoped></style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import Header from '@/components/shared/Header'

export default {
  components: {
    Auth,
    Header
  },
  data() {
    return {
      headerContent: {
        title: 'サインアウト'
      }
    }
  },
  methods: {
    ...mapActionsAuth(['signOut'])
  },
  created() {
    this.signOut()
    this.$router.push('/sign-in')
  }
}
</script>
